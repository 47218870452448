<template>
    <div class="content">
      <div class="config_item">
        <el-row :gutter="15">
          <el-col :span="3" class="textRight"><span>实名认证开关</span></el-col>
          <el-col :span="21"><el-switch v-model="config.verified" @change="_changeVerifiedStatus"></el-switch></el-col>
        </el-row>
      </div>
      <div class="config_item">
        <el-row :gutter="15">
          <el-col :span="3" class="textRight"><span>收银员押金</span></el-col>
          <el-col :span="21"><el-switch :value="config.openDeposit" @change="_changeDepositStatus"></el-switch></el-col>
        </el-row>
      </div>
      <div class="config_item" v-for="(item, index) in configList" :key="index">
        <el-row :gutter="15">
          <el-col :span="3" class="textRight"><span>{{ item.configName }}</span></el-col>
          <el-col v-if="item.configType == 'GOODS_AUTO_NUMBER'" :span="21"><el-switch :value="item.configValue == 'true'" @change="_changeGoodsAutoNumber(item)"></el-switch></el-col>
        </el-row>
      </div>
    </div>
</template>

<script>
import {changVerified, getConfig, changeOpenDeposit, getOpenDeposit, getMarketConfigList, updateMarketConfigList} from "../../../services/sysapi";

export default {
  name: "index",
  data(){
    return {
      config:{
        verified:false,
        openDeposit: false,
        goodsAutoNumber: false
      },
      configList: []
    }
  },
  created(){
    this._getConfig()
    this._getDeposit()
    this._getMarketConfigList()
  },
  methods:{
    _getMarketConfigList() {
      getMarketConfigList().then(res => {
        // const item = res.data.filter(item => item.configType == 'GOODS_AUTO_NUMBER')[0]
        // this.config.goodsAutoNumber = Boolean(item.configValue)
        this.configList = res.data
      })
    },
    _getConfig(){
      getConfig().then(({data:{enable}})=>{
        this.config.verified = enable
      })
    },
    _getDeposit () {
      getOpenDeposit().then(({data: {openDeposit}})=>{
        this.config.openDeposit = openDeposit
      })
    },
    _changeVerifiedStatus(){
      changVerified({enable:this.config.verified}).then(res=>{
        this._getConfig()
      })
    },
    _changeDepositStatus (e) {
      changeOpenDeposit ({ openDeposit: e }).finally(() => {
        this._getDeposit()
      })
    },
    _changeGoodsAutoNumber (target) {
      const postData = {
        id: target.id,
        configValue: target.configValue == 'true' ? 'false' : 'true'
      }
      updateMarketConfigList(postData).then(res => {
        this._getMarketConfigList()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  background: #fff;
  border-radius: 6px;
  padding: 20px;

  .config_item {
    margin-bottom: 20px;
    height: 32px;
    line-height: 32px;
  }
}

</style>